<app-modal [goBack]="step === 1 ? false : true" (goBackEvent)="handleGoBack()">
    <div class="register-content">
        @if(step === 1) {
            <div class="title-section">
                <h2>Create account</h2>
                <p>
                    This site uses the IDB Extranet as a secure website exclusively for IDB employees and partners. To login, it is necessary to have an email address and password.
                </p>
                <p>
                    For help please contact:
                    <strong>extranetsupport&#64;iadb.org</strong>
                </p>
            </div>
            <form [formGroup]="registerForm">
                <div class="inputs-section">
                    <label for="email">Email</label>
                    <div class="input-container">
                        <input type="email" id="email" name="email" class="email-input" formControlName="email" [class.error]="emailError" (blur)="verifyEmailErros()">
                        @if(emailError) { <img src="/assets/icons/information-red.svg" alt="Search Icon" class="error-icon"> }
                    </div>
                    @if(emailError) { <label class="error-label">{{ emailMsgError }}</label> }
                    @if (login) {
                        <div class="login">
                            <span (click)="loginAccount()">Log in</span>
                        </div>
                    }
                    <label for="confirmEmail" class="confirm-email-label">Confirm Email</label>
                    <div class="input-container">
                        <input type="email" id="confirmEmail" name="confirmEmail" class="email-input" formControlName="confirmEmail" [class.error]="confirmEmailError" (blur)="verifyConfirmEmailErrors()">
                        @if(confirmEmailError) { <img src="/assets/icons/information-red.svg" alt="Search Icon" class="error-icon"> }
                    </div>
                    @if(confirmEmailError) { <label class="error-label">Email address do not match. Please, try again.</label> }
                </div>
                <div class="buttons-section step1">
                    <button 
                        type="button"
                        class="btn-continue"
                        (click)="nextStep()"
                        [disabled]="!registerForm.get('email')?.valid || !registerForm.get('confirmEmail')?.valid || registerForm.hasError('emailMismatch', ['confirmEmail']) || confirmEmailError || emailError"
                        [class.disabled-button]="!registerForm.get('email')?.valid || !registerForm.get('confirmEmail')?.valid || registerForm.hasError('emailMismatch', ['confirmEmail']) || confirmEmailError || emailError"
                    >Continue</button>
                    <button type="button" class="btn-cancel" (click)="closeModal()">Cancel</button>
                </div>
            </form>
        } @else {
            <div class="title-section">
                <h2>Create account</h2>
            </div>
            <form [formGroup]="registerForm" (ngSubmit)="createAccount()">
                <div class="inputs-section">
                    <label for="first-name">First Name</label>
                    <div class="input-container">
                        <input type="text" id="first-name" name="first-name" formControlName="firstName" [class.error]="nameError" (blur)="verifyNameErrors()">
                        @if(nameError) { <img src="/assets/icons/information-red.svg" alt="Search Icon" class="error-icon"> }
                    </div>
                    @if(nameError) { <label class="error-label">This field cannot be empty.</label> }
                    <label for="last-name" class="space-24">Last Name</label>
                    <div class="input-container">
                        <input type="text" id="last-name" name="last-name" formControlName="lastName" [class.error]="lastNameError" (blur)="verifyLastNameErrors()">
                        @if(lastNameError) { <img src="/assets/icons/information-red.svg" alt="Search Icon" class="error-icon"> }
                    </div>
                    @if(lastNameError) { <label class="error-label">This field cannot be empty.</label> }
                    <label for="preferred-language" class="space-24">Preferred language</label>
                    <div class="preferred-language-wrapper">
                        <select class="preferred-language" id="preferred-language" formControlName="preferredLanguage">
                            <option value="en">English</option>
                            <option value="es">Español</option>
                            <option value="pr">Português</option>
                        </select>
                    </div>
                    <div class="agree-to-platform">
                        <input type="checkbox" id="agree" name="agree" formControlName="agree">
                        <label for="agree">Agree to the platform's </label><a (click)="openTermsOfService()">terms of service</a>
                    </div>
                </div>
                <div class="buttons-section">
                    <button type="submit" class="btn-continue" [class.disabled-button]="registerForm.invalid || isCreatingAccount" [disabled]="!registerForm.valid">
                        @if(!isCreatingAccount) {
                            Create Account
                        } @else {
                            Creating...
                        }
                    </button>
                    <button type="button" class="btn-cancel" (click)="closeModal()">Cancel</button>
                </div>
            </form>
        }
    </div>
</app-modal>

