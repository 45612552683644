<header>
    <div class="header-container">
        <div class="header-logo">
            <img (click)="navigateTo('')" src="assets/images/amazonia-forever.svg" alt="amazonia-forever">
        </div>
        <div class="header-menu">
            <div class="menu-item" (click)="navigateTo('')" [class.active]="activeMenu === '' || activeMenu === '/'">Home</div>
            <div class="menu-item" (click)="navigateTo('/resource-flows')" [class.active]="activeMenu === '/resource-flows'">Resource flows</div>
            <div class="menu-item" (click)="navigateTo('/projects')" [class.active]="activeMenu === '/projects'">Projects</div>
            <div class="menu-item" (click)="navigateTo('/data-sources')" [class.active]="activeMenu === '/data-sources'">Data source</div>
            <div class="menu-item" (click)="navigateTo('/about-us')" [class.active]="activeMenu === '/about-us'">About us</div>
        </div>
        <div class="header-login">
            @if (this.userName === '') {
                <button class="btn-access" (click)="login()">Partner’s access</button>
                <button class="btn-create" (click)="createAccount()">Create account</button>
            }
            @if (this.userName !== '') {
                <label class="user-name">Hello {{ userName }}! </label>
                <button class="btn-log-out" (click)="logout()"><span>Log out</span> <img src="/assets/icons/log-out.svg"></button>
            }
        </div>
        <div class="header-burger">
            <label class="toggle-button" (click)="sidebarVisible = true">
                <span></span>
                <span></span>
                <span></span>
            </label>
        </div>
    </div> 
</header>

<p-sidebar [(visible)]="sidebarVisible">
    <div class="sidebar-content">
        <div class="m-header-logo">
            <img (click)="navigateTo('')" src="assets/images/amazonia-forever.svg" alt="amazonia-forever">
        </div>
        <div class="m-header-menu">
            <div class="menu-item" (click)="navigateTo('')" (click)="sidebarVisible = false">Home</div>
            <div class="menu-item" (click)="navigateTo('/resource-flows')" (click)="sidebarVisible = false">Resource flows</div>
            <div class="menu-item" (click)="navigateTo('/projects')" (click)="sidebarVisible = false">Projects</div>
            <div class="menu-item" (click)="navigateTo('/data-sources')" (click)="sidebarVisible = false">Data source</div>
            <div class="menu-item" (click)="navigateTo('/about-us')" (click)="sidebarVisible = false">About us</div>
        </div>
        <div class="m-header-login">
            @if (this.userName === '') {
                <button class="btn-access" (click)="login()">Partner’s access</button>
                <button class="btn-create" (click)="createAccount()">Create account</button>
            }
            @if (this.userName !== '') { 
                <label class="user-name">Hello {{ userName }}! </label>
                <button class="btn-log-out" (click)="logout()"><span>Log out</span> <img src="/assets/icons/log-out.svg"></button>
            }
        </div>
    </div>
</p-sidebar>
