import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { LogOutModalComponent } from './log-out-modal/log-out-modal.component';

@Component({
  selector: 'app-side-menu',
  standalone: true,
  imports: [],
  templateUrl: './side-menu.component.html',
  styleUrl: './side-menu.component.scss'
})
export class SideMenuComponent {

  public menuOpen = false;

  constructor(private router: Router, private authService: MsalService, public newDialog: MatDialog) {}

  isActive(route: string): boolean {
    return this.router.url === route;
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  async logout(){
    this.newDialog.open(LogOutModalComponent, {
        width: '449px'
    });
  }

}
