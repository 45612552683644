<div class="mobile-top-bar">
    <button class="hamburger-btn" (click)="toggleMenu()">
      <img src="assets/icons/menu.svg"/>
    </button>
  
    <div class="mobile-logo" (click)="navigateTo('/my-dashboard')">
      <img src="assets/images/amazonia-forever.svg" alt="Amazonia Forever"/>
    </div>
</div>
  
<div class="side-menu-conteiner" [class.open]="menuOpen">
  <button class="close-btn" (click)="toggleMenu()">
    <img src="assets/icons/cross-modal.svg"/>
  </button>

  <div class="menu-logo">
    <img src="assets/images/amazonia-forever.svg" alt="Amazonia Forever" 
          class="logo" 
          (click)="navigateTo('/my-dashboard')" />
  </div>

  <ul class="menu-list">
    <li>
      <button class="menu-item" 
              [class.active]="isActive('/my-dashboard')" 
              (click)="navigateTo('/my-dashboard')">
        <img src="assets/icons/sm-dashboard.svg" 
              alt="Dashboard" class="menu-icon" 
              [class.menu-icon-active]="isActive('/my-dashboard')"/>
        My Dashboard
      </button>
    </li>
    <li>
      <button class="menu-item" 
              [class.active]="isActive('/favorites')" 
              (click)="navigateTo('/favorites')">
        <img src="assets/icons/heart.svg" alt="Favorites" 
              class="menu-icon"
              [class.menu-icon-active]="isActive('/favorites')"/>
        Favorites
      </button>
    </li>
    <li>
      <button class="menu-item" 
              [class.active]="isActive('/resource-flows')" 
              (click)="navigateTo('/resource-flows')">
        <img src="assets/icons/sm-chart.svg" alt="Resource Flows" 
              class="menu-icon"
              [class.menu-icon-active]="isActive('/resource-flows')"/>
        Resource Flows
      </button>
    </li>
    <li>
      <button class="menu-item" 
              [class.active]="isActive('/projects')" 
              (click)="navigateTo('/projects')">
        <img src="assets/icons/sm-folder.svg" alt="Projects" 
              class="menu-icon" 
              [class.menu-icon-active]="isActive('/projects')"/>
        Projects
      </button>
    </li>
    <li>
      <button class="menu-item" 
              [class.active]="isActive('/data-sources')" 
              (click)="navigateTo('/data-sources')" >
        <img src="assets/icons/sm-inbox.svg" 
              alt="Data Source" class="menu-icon"
              [class.menu-icon-active]="isActive('/data-sources')"/>
        Data Source
      </button>
    </li>
    <li>
      <button class="menu-item" 
              [class.active]="isActive('/about-us')" 
              (click)="navigateTo('/about-us')">
        <img src="assets/icons/sm-information.svg" 
              alt="About Us" class="menu-icon"
              [class.menu-icon-active]="isActive('/about-us')"/>
        About Us
      </button>
    </li>
    <li class="menu-item-logout">
      <button class="menu-item" (click)="logout()">
        <img src="assets/icons/sm-log-out.svg" 
              alt="Log Out" class="menu-icon" />
        Log Out
      </button>
    </li>
  </ul>
  
  <div class="menu-logo-footer">
      <img src="assets/icons/side-menu-logo1.svg" alt="Amazonia Forever" class="logo" />
      <img src="assets/icons/side-menu-logo2.svg" alt="Amazonia Forever" class="logo" />
      <img src="assets/icons/side-menu-logo3.svg" alt="Amazonia Forever" class="logo" />
  </div>
</div>
  