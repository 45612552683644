<footer>
    <div class="footer-conteiner">
        <div class="footer-logos">
            <img src="assets/images/idb-logo.svg" alt="bid-logo">
            <img src="assets/images/idb-invest-logo.svg" alt="bid-logo-invest">
            <img src="assets/images/idb-lab-logo.svg" alt="bid-logo-lab">
        </div>
        <div class="footer-menu">
            <div class="menu-item" routerLink="">Home</div>
            <div class="menu-item" routerLink="/resource-flows">Resource flows</div>
            <div class="menu-item" routerLink="/projects">Projects</div>
            <div class="menu-item" routerLink="/data-sources">Data source</div>
            <div class="menu-item" routerLink="/about-us">About us</div>
        </div>
    </div>
</footer>
