import { ChangeDetectorRef, Component, effect, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { SidebarModule } from 'primeng/sidebar';
import { MatDialog } from '@angular/material/dialog';
import { RegisterAccountComponent } from 'src/app/features/account/create/create-account.component';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { BrowserCacheLocation, EventMessage, EventType, InteractionStatus, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { Subject, filter, takeUntil } from 'rxjs';
import { CreateAccount } from '@interfaces/account.interface';
import { ResendEmailComponent } from 'src/app/features/account/resend-email/resend-email.component';
import { environment } from '@environment/environment.dev';
import { loggerCallback } from 'src/app/app.config';
import { ProjectsService } from '@services/projects.service';
import { HomeService } from '@services/home.service';
import { DashboardService } from '@services/dashboard.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterModule, SidebarModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {

  private readonly projectService = inject(ProjectsService);
  private readonly dashboardService = inject(DashboardService);
  
  private readonly _destroying$ = new Subject<void>();
  private readonly router = inject(Router);
  private readonly homeService = inject(HomeService);

  public loginDisplay = false;
  public sidebarVisible: boolean = false;
  public userName = '';
  public activeMenu = '';
  public url = '';

  constructor(public dialog: MatDialog,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private cd: ChangeDetectorRef) {
      effect(() => {
        this.url = this.projectService.getDataSourceUrl();
        this.activeMenu = this.url;
      });
    }
  
  async ngOnInit(): Promise<void> {
    await this.initializeAuth();
    this.authService.handleRedirectObservable().subscribe({});
    this.setLoginDisplay();

    if (typeof window !== 'undefined') {
      const currentUrl = window.location.pathname;
      this.activeMenu = currentUrl;
    }

    this.authService.instance.enableAccountStorageEvents();
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } else {
          this.setLoginDisplay();
        }
      });
    
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      })
  }

  async initializeAuth(): Promise<void> {
    const authResponse = await this.authService.handleRedirectObservable().toPromise();
    if (authResponse) {
      this.initializeAuthLogicB2C();
    }
  }

  checkAndSetActiveAccount(){
    let activeAccount = this.authService.instance.getActiveAccount();
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  createAccount(): void { 
    this.sidebarVisible = false;
    const dialogRef = this.dialog.open(RegisterAccountComponent, {
      height: 'auto',
      width: '400px',
    });

    dialogRef.componentInstance.verifiedEmail.subscribe(async (account: CreateAccount) => {
      this.dialog.open(ResendEmailComponent, {
        height: '236px',
        width: '400px',
        data: {
          createAccount: account
        }
      });
    });
  }

  async login() {
    await this.authService.instance.loginRedirect({
       scopes: ['openid', 'profile'],
       redirectUri: environment.msalConfig.auth.redirectUri
    });
  };

  private initializeAuthLogicB2C(): void {
    this.authService.instance = new PublicClientApplication({
      auth: {
        clientId: environment.msalConfig.auth.clientId,
        authority: environment.msalConfig.auth.authority,
        redirectUri: environment.msalConfig.auth.redirectUri,
        postLogoutRedirectUri: '/',
        knownAuthorities: [`${environment.msalConfig.auth.azureB2CDomain}.b2clogin.com`],
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
      },
      system: {
        allowNativeBroker: false, 
        loggerOptions: {
          loggerCallback: loggerCallback,
          logLevel: LogLevel.Info,
          piiLoggingEnabled: false,
        },
      },
    });
  }

  setLoginDisplay() {
    const accounts = this.authService.instance.getAllAccounts();
    this.dashboardService.userInformation(accounts[0]);
    this.loginDisplay = accounts.length > 0;
  
    if (accounts.length > 0 && accounts[0].name) {
      this.userName = accounts[0].name;
    } else {
      this.userName = '';
    }
    this.cd.detectChanges();
  }

  async logout(){
    try {
      await this.authService.logoutRedirect();
      console.log('Logout successful');
    } catch (error) {
      console.error('Logout error', error);
    }
  }

  navigateTo(route: string) {
    this.activeMenu = route;
    this.router.navigate([route]);
    this.homeService.updateOrigenPage(route);
  }

}
