import { Routes } from '@angular/router';

export const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./features/home/home.routes').then(m => m.HOME_ROUTES)
    },
    {
        path: 'data-sources',
        loadChildren: () => import('./features/data-sources/data-sources.routes').then(m => m.DATA_SOURCE_ROUTES)
    },
    {
        path: 'resource-flows',
        loadChildren: () => import('./features/resource-flows/resource-flows.routes').then(m => m.RESOURCE_FLOWS_ROUTES)
    },
    {
        path: 'projects',
        loadChildren: () => import('./features/projects/projects.routes').then(m => m.PROJECTS_ROUTES)
    },
    {
        path: 'about-us',
        loadChildren: () => import('./features/about-us/about-us.routes').then(m => m.ABOUT_US_ROUTES)
    },
    {
        path: 'my-dashboard',
        loadChildren: () => import('./features/dashboard/dashboard.routes').then(m => m.DASHBOARD_ROUTES)
    },
    {
        path: '',
        loadChildren: () => import('./features/dashboard/sections/project/project.routes').then(m => m.DASHBOARD_PROJECTS_ROUTES)
    },
];
