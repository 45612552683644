import { Component, inject } from '@angular/core';
import { ModalComponent } from '@shared/components/modals/modal.component';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CreateAccount } from '@interfaces/account.interface';
import { AccountService } from '@services/account.service';
import { Subject } from 'rxjs';
import { environment } from '@environment/environment.dev';
import { MsalService } from '@azure/msal-angular';
import { TermsOfServiceComponent } from '../terms-of-service/terms-of-service.component';

@Component({
  selector: 'app-create-account',
  standalone: true,
  imports: [ModalComponent, ReactiveFormsModule],
  templateUrl: './create-account.component.html',
  styleUrl: './create-account.component.scss'
})
export class RegisterAccountComponent {

  public step = 1;
  public registerForm: FormGroup;
  public confirmEmailError = false;
  public emailError = false;
  public emailMsgError = '';
  public login = false;
  public nameError = false;
  public lastNameError = false;
  public isCreatingAccount = false;

  private accountService = inject( AccountService );

  public verifiedEmail: Subject<CreateAccount> = new Subject();

  constructor(
    public dialog: MatDialogRef<ModalComponent>,
    private fb: FormBuilder,
    private authService: MsalService,
    public newDialog: MatDialog
  ) {
    this.registerForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      confirmEmail: ['', [Validators.required, Validators.email]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      preferredLanguage: ['en', Validators.required],
      agree: [false, Validators.requiredTrue]
    }, {
      validator: this.emailMatcher
    });
  }

  emailMatcher(group: FormGroup) {
    const emailControl = group.get('email');
    const confirmEmailControl = group.get('confirmEmail');

    if (emailControl?.value !== confirmEmailControl?.value) {
      confirmEmailControl?.setErrors({ emailMismatch: true });
    } else {
      confirmEmailControl?.setErrors(null);
    }
  }

  public verifyEmailErros() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const email = this.registerForm.get('email')?.value;
    if (!emailRegex.test(email)) {
      this.emailError = true;
      this.emailMsgError = 'Please enter a valid email address.';
    } else {
      this.emailError = false;
    }
  }

  public verifyConfirmEmailErrors() {
    this.confirmEmailError = this.registerForm.get('email')?.value !== this.registerForm.get('confirmEmail')?.value;
  }

  public verifyNameErrors() {
    this.nameError = this.registerForm.get('firstName')?.value === '';
  }

  public verifyLastNameErrors() {
    this.lastNameError = this.registerForm.get('lastName')?.value === '';
  }

  public nextStep() {
    if (this.step === 1 &&
        this.registerForm?.get('email')?.valid &&
        this.registerForm?.get('confirmEmail')?.valid) {
      this.step = 2;
    }
  }

  public handleGoBack() {
    this.step = 1;
  }

  public closeModal(): void {
    this.dialog.close();
  }

  public openTermsOfService() {
    this.newDialog.open(TermsOfServiceComponent, {
      maxHeight: '80vh',
      width: 'auto'
    });
  }

  public createAccount() {
    if (this.registerForm.valid && !this.isCreatingAccount) {
      this.isCreatingAccount = true;
      const accountData: CreateAccount = {
        email: this.registerForm.get('email')?.value,
        firstName: this.registerForm.get('firstName')?.value,
        lastName: this.registerForm.get('lastName')?.value,
        preferredLanguage: this.registerForm.get('preferredLanguage')?.value,
        forceEmail: false
      };
      this.accountService.register(accountData).subscribe({
        next: response => {
          this.verifiedEmail.next(accountData);
          this.dialog.close();
        },
        error: error => {
          if(error.status === 409) {
            console.error('Account already exists');
            this.step = 1;
            this.emailError = true;
            this.login = true;
            this.isCreatingAccount = false;
            this.emailMsgError = 'An account already exist with this email address.';
          }
        }
      });
    }
  }

  async loginAccount() {
    await this.authService.instance.loginRedirect({
       scopes: ['openid', 'profile'],
       redirectUri: environment.msalConfig.auth.redirectUri
    });
    this.dialog.close();
  };
}
