<app-modal>
    <div class="terms-content">
        <h3>Extranet Terms and Conditions</h3>
        <p>
            Please read these terms and conditions (“Terms”) carefully before using the Inter-American Development Bank (the “Bank”) extranet. The extranet permits authorized outside users, such as yourself, limited access to the Bank’s internal website. By accessing the Bank’s extranet, you agree to be bound by these Terms.
        </p>
        <p>
            <strong>Right to Modify Terms and Conditions</strong>
            By accessing the extranet, you, the user, accept that the Bank may revise these Terms at any time by updating this posting. As a user, you should check this extranet from time to time to review the then current Terms because they are binding on you. If at any time these Terms are no longer acceptable to you, please stop using the extranet.
        </p>
        <p>
            <strong>The Bank’s Rights and Control of the Extranet</strong>
            The Bank has the right, but not the obligation, to take the following actions in its sole discretion at any time and for any reason: i) establish general practices and limits concerning use of the extranet; ii) restrict, suspend or terminate your access to all or any part of the extranet; iii) change, suspend or discontinue all or any part of the extranet; iv) refuse, move or remove any information or data submitted to the extranet ; v) refuse, move or remove any content that is available on the extranet.
        </p>
        <p>
            <strong>Use of and Access to Information on the Extranet</strong>
            The user may use the extranet only for lawful purposes and only for purposes specifically authorized by the Bank. The user is fully responsible for all activities resulting from such use, including unauthorized use and agrees not to permit any unauthorized use of the extranet. The user is responsible for protecting the confidentiality of all information available on the extranet according to the IDB’s Access to Information Policy, and is fully responsible for all activities that occur under the user’s access code and username . The Bank cannot and will not be responsible for any losses or damages arising out of a breach of this clause. The Bank may cancel, modify or replace any authorized user, identification or password, at any time and in its sole discretion.
        </p>
        <p>
            <strong>License Grants</strong>
            The Bank hereby grants to you, and you hereby accept, a limited, nonexclusive and revocable license to access and use the Bank’s applications and content solely for user’s internal business use and to display and print the information on this extranet, provided that all displays and prints of such information are solely for internal business use.
        </p>
        <p>
            <strong>Reservation of Rights (Intellectual Property)</strong>
            The Bank retains full and complete right and title to the extranet, user content, all component parts thereof and to all intellectual and industrial property rights therein (including any applicable copyrights, trademarks and patents), subject only to user’s rights, if any, in any user information and to the limited express licenses granted herein. Except as expressly provided herein, you shall not reproduce, duplicate, copy, sell, resell or exploit for any commercial purpose the extranet or the user content or any displays, prints or downloads thereof. You shall not reverse engineer, decompile, or otherwise attempt to derive source code from any software accessible or available through the extranet. Nothing contained on this extranet should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any trademark, logos or service-marks ("Trademarks") displayed on this extranet without the written permission of the Bank or its affiliates or such third party that may own the Trademarks displayed on this extranet.
        </p>
        <p>
            <strong>Limitation of Liability</strong>
            The Bank has measures in place to maintain the security of the extranet and its content. As an Internet-based system, however, the extranet may be subject to viruses or unauthorized access. While the Bank attempts to reduce the risk associated with the extranet, access and use of the extranet is at your own risk. The Bank does not warrant that the extranet will operate error-free or that it or any of the Bank’s servers are completely free from computer viruses, contamination, or other harmful or destructive features. If your use of the extranet results in any costs or expenses, including, without limitation, the need for servicing or replacing equipment or data, the Bank shall not be responsible for those costs or expenses.
        </p>
        <p>
            TWhile the Bank endeavors to ensure that this extranet is normally available 24 hours a day, the Bank shall not be liable if for any reason the extranet is unavailable at any time or for any period. Access to the extranet may be suspended temporarily and without notice in the case of system failure, maintenance or repair or for reasons beyond the Bank’s control.
        </p>
        <p>
            The material on this extranet is provided “as is”, without any conditions, warranties or other terms of any kind either express or implied. Accordingly, to the maximum extent permitted by law, the Bank provides you with this extranet on the basis that the Bank excludes all representations, warranties, conditions and other terms (including, without limitation, the conditions as to non-infringement, the conditions implied by law of satisfactory quality, fitness for purpose and the use of reasonable care and skill) which but for this legal notice might have effect in relation to this extranet.
        </p>
        <p>
            <strong>Termination or Suspension of User Access to Extranet</strong>
            We may terminate or suspend access to the extranet immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms. All provisions of the Terms that by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability. Upon termination, the user’s right to use the extranet will immediately cease.
        </p>
        <p>
            <strong>Governing Law and Arbitration</strong>
            Any controversy or claim arising out of or relating to these Terms or the extranet that cannot be settled by amicable agreement shall, upon either party’s giving notice of the difference or dispute to the other, be settled by the mutual agreement or, failing such agreement, by binding arbitration according to the law of the District of Columbia, without regard to its conflict of law provisions, and administered by the American Arbitration Association (AAA) in accordance with its Commercial Arbitration Rules then in effect, subject to the Bank’s immunities. The arbitration shall take place in the District of Columbia and shall be performed by one arbitrator appointed in accordance with the applicable AAA rules. The arbitrator shall have legal counsel if so desired or if one of the parties so requests. The expenses of any arbitration proceeding shall be borne equally by the yourself and the Bank.
        </p>
        <p>
            The United States District Court for the District of Columbia shall have jurisdiction in connection with these Terms exclusively for the following legal actions: 1) to compel arbitration by a party that refuses to submit to arbitration after receiving notice from the other party; 2) to enforce an arbitration award issued by the arbitrators following the arbitration clause; 3) to decide if the arbitrators exceeded their authority by addressing issues not raised or issues not pertinent to the arbitration; and/or 4) to determine whether proper procedures were followed during arbitration. The United States District Court for the District of Columbia shall have no jurisdiction to review or decide on the matter under arbitration.
        </p>
        <p>
            <strong>Privileges and Immunities</strong>
            Nothing in these Terms shall operate to restrict, limit or defeat any rights, privileges or immunities granted to the Bank or Bank personnel under any treaty or domestic or international law, which are specifically reserved. Nothing herein shall preclude the Bank from taking whatever actions are necessary to prevent immediate, irreparable harm to its privileges and immunities.    
        </p>
        <p>
            <strong>Release</strong>
            In consideration of your use of the extranet, with full knowledge and acceptance of the risks associated therewith, and with full understanding of the above issues/conditions, you in using the extranet forever release and discharge the Bank, its directors, officers, employees, agents and affiliates from and against any and all claims, liabilities, or causes of action for negligence, whether foreseeable or unforeseeable, that you and/or your heirs, executors, administrators, successors, representatives and assigns may have at any time against the Bank, arising out of or in connection with, directly or indirectly, your use of the extranet.    
        </p>
    </div>
</app-modal>
