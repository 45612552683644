import { Component } from '@angular/core';
import { ModalComponent } from '@shared/components/modals/modal.component';

@Component({
  selector: 'app-terms-of-service',
  standalone: true,
  imports: [ModalComponent],
  templateUrl: './terms-of-service.component.html',
  styleUrl: './terms-of-service.component.scss'
})
export class TermsOfServiceComponent {

}
