import { Component, Inject, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountService } from '@services/account.service';
import { ModalComponent } from '@shared/components/modals/modal.component';

@Component({
  selector: 'app-resend-email',
  standalone: true,
  imports: [ModalComponent],
  templateUrl: './resend-email.component.html',
  styleUrl: './resend-email.component.scss'
})
export class ResendEmailComponent {
  private accountService = inject( AccountService );
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  public resendEmail() {
    this.data.createAccount.forceEmail = true;
    this.accountService.register(this.data.createAccount).subscribe({
      next: response => {
        console.log('Account created successfully', response);
      },
      error: error => {
        console.error('Error creating account', error);
      }
    });
  }
}
