import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer/footer.component';
import { environment } from '@environment/environment.dev';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { BrowserCacheLocation, EventMessage, EventType, InteractionStatus, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';
import { loggerCallback } from './app.config';
import { SideMenuComponent } from '@shared/components/side-menu/side-menu.component';
import { DashboardService } from '@services/dashboard.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, FooterComponent, SideMenuComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'amazonia';
  private readonly dashboardService = inject(DashboardService);
  private readonly destroying$ = new Subject<void>();
  public loginDisplay = false;
  public userName = '';

  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private cd: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initializeAuth();
    this.subscribeToAuthEvents();
  }

  private initializeAuth(): void {
    this.authService.handleRedirectObservable().subscribe(authResponse => {
      if (authResponse) {
        this.configureMsalInstance();
      }
      this.setLoginDisplay();
    });
  }

  private configureMsalInstance(): void {
    this.authService.instance = new PublicClientApplication({
      auth: {
        clientId: environment.msalConfig.auth.clientId,
        authority: environment.msalConfig.auth.authority,
        redirectUri: environment.msalConfig.auth.redirectUri,
        postLogoutRedirectUri: '/',
        knownAuthorities: [`${environment.msalConfig.auth.azureB2CDomain}.b2clogin.com`],
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
      },
      system: {
        allowNativeBroker: false,
        loggerOptions: {
          loggerCallback: loggerCallback,
          logLevel: LogLevel.Info,
          piiLoggingEnabled: false,
        },
      },
    });
  }

  private subscribeToAuthEvents(): void {
    this.authService.instance.enableAccountStorageEvents();

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED)
      )
      .subscribe(() => this.handleAccountChange());

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this.destroying$)
      )
      .subscribe(() => this.setLoginDisplay());
  }

  private handleAccountChange(): void {
    if (this.authService.instance.getAllAccounts().length === 0) {
      window.location.pathname = "/";
    } else {
      this.setLoginDisplay();
    }
  }

  private setLoginDisplay(): void {
    const accounts = this.authService.instance.getAllAccounts();
    this.loginDisplay = accounts.length > 0;
    if (this.loginDisplay) {
      const idToken = accounts[0]?.idToken ?? '';
      const email = accounts[0]?.idTokenClaims?.['email'] ?? '';
      this.userName = accounts[0].name ? accounts[0].name : '';
      this.getPreferences(email, idToken);
      this.router.navigate(['/my-dashboard']); 
    }
    this.cd.detectChanges();
  }

  public async getPreferences(email: any, idToken: string) {
    await this.dashboardService.getUserPreferences(email, idToken).subscribe({
        next: (response: any) => {
          this.dashboardService.setUserPreferences(response);
        },
        error: error => {
          if(error.status === 500) {
            this.logOut();
          }
        }
    });
  }

  public async logOut() { 
    try {
      await this.authService.logoutRedirect();
    } catch (error) {
      console.error('Logout error', error);
    }
  }

  ngOnDestroy(): void {
    this.destroying$.next();
    this.destroying$.complete();
  }
}
