<div class="layout" [class.with-menu]="loginDisplay">
    <!-- Header -->
    @if (!loginDisplay) {
        <app-header></app-header>
    } 
  
    <!-- Side Menu --> 
    @if (loginDisplay) {
        <app-side-menu></app-side-menu>
    }
  
    <!-- Main Content -->
    <div class="main-container">
      <router-outlet></router-outlet>
      @if (!loginDisplay) {
        <app-footer></app-footer>
      }
    </div>
</div>
