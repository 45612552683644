import { HttpInterceptorFn } from '@angular/common/http';

export const headersInterceptor: HttpInterceptorFn = (req, next) => {
  const authToken = 'a68c9fbaa81d4682a29ee4d324d89b94';

  const authReq = req.clone({
    setHeaders: {
      'Ocp-Apim-Subscription-Key': `${authToken}`
    }
  });

  return next(authReq);
};
