import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environment/environment.dev';
import { CreateAccount } from '@interfaces/account.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private readonly _http = inject( HttpClient );
  private readonly _endpoint = environment.apiURL;

  constructor() { }

  public register(accountData: CreateAccount): Observable<any> {
    return this._http.post<any>(`${this._endpoint}Register`, accountData);
  }
}
